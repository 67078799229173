// Gravity Forms Post Render 
jQuery(document).on('gform_post_render', function(event, form_id, current_page) {

    jQuery('.gform_body input').each(function(i, obj) {

        let current_gf_field = jQuery(this).attr('value');
        
        if (current_gf_field.startsWith('http') && current_gf_field.includes('salesgenie.com')) {

            jQuery(this).attr('value', window.location.href);

        }

    });
 
    // Gravity Form 12, the Seamless Registration form
    if (form_id === 18) {
 
        const nxg_username = Cookies.get('userSubmittedEmail');
 
        if (nxg_username) {
  
            jQuery('#input_18_1').attr('value', nxg_username).attr('readonly', 'true');
            //Add class to label if email value found
            if(jQuery('#input_18_1').val()){
                jQuery('#input_18_1').closest('li').find('label').addClass('active-label-value');
            }
 
        }
 
    }

    //Enable floating label class on form labels which have values in input
    jQuery('.floating-labels-form .enable-label input, .floating-labels-form .enable-label textarea').each(function(field) {
        if (jQuery(this).val() !== "") {
            jQuery(this).parent().addClass('has-value');
            jQuery(this).parent().parent().find('label').addClass('active-label-value');
        }
    });
  
});

jQuery(document).ready(function() {

    // force cursor placement on input masked phone field only when empty of characters
   var maskedPhones = document.querySelectorAll('.gfield .ginput_container_phone input');

   for (i = 0; i < maskedPhones.length; ++i) {

      maskedPhones[i].addEventListener('mouseup', function(e) {

            if ( e.currentTarget.value.replace(/\(|\)|_|-|\./g,'').trim() == '' ) {

               e.currentTarget.setSelectionRange(0,0);

            }

       });

   }

    // insert the show/hide eye icons after the .password-eye input
   jQuery('<i class="show-password password-eye-icon fas fa-eye" aria-hidden="true"></i><i class="hide-password password-eye-icon fas fa-eye-slash" aria-hidden="true"></i>').insertAfter('.password-eye input');


   // show/hide the masked password when the eye icons are clicked
   jQuery('.password-eye-icon').click(function() {

      if (jQuery(this).hasClass('show-password')) {

         jQuery('.password-eye input').attr('type', 'text');

         jQuery(this).hide();

         jQuery('.hide-password').show();

      } else if (jQuery(this).hasClass('hide-password')) {

         jQuery('.password-eye input').attr('type', 'password');

         jQuery(this).hide();

         jQuery('.show-password').show();

      }

   });

});

// jQuery(document).on('click', '.gform_button', function() {

//     let formTarget = jQuery(this).parents().eq(1).prop('target');

//     if (!formTarget.includes('gform_ajax')) {
//         jQuery('<img id="gform_ajax_spinner_9" class="gform_ajax_spinner" src="https://' + window.location.hostname + '/wp-content/plugins/gravityforms/images/spinner.svg" alt="">').insertAfter(this);
//     }

//     jQuery(this).val('SENDING...').attr('style', 'pointer-events:none!important');

// });